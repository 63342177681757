var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-row',[_c('b-col',[_c('b-table',{attrs:{"striped":"","hover":"","responsive":"","items":_vm.list,"fields":_vm.columns,"busy":_vm.isBusy,"show-empty":"","empty-text":"No matching records found"},scopedSlots:_vm._u([{key:"cell(image_url)",fn:function(data){return [(data.item.image_url)?_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-img',{staticClass:"box-image1-1",attrs:{"src":data.item.image_url,"fluid":""},on:{"error":_vm.handleImageSrc}})],1):_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-img',{staticClass:"box-image1-1",attrs:{"src":"/img/default-placeholder.png","fluid":""}})],1)]}},{key:"cell(name)",fn:function(data){return [_c('div',{staticClass:"text-left"},[_c('router-link',{attrs:{"to":'/coupon/details/' + data.item.id}},[_c('b-button',{staticClass:"name-link p-0",attrs:{"variant":"link"}},[_vm._v(" "+_vm._s(data.item.name)+" ")])],1),_c('div',{staticClass:"line-clamp2 description"},[_vm._v(_vm._s(data.item.detail))]),_c('div',{staticClass:"badge-container"},_vm._l((data.item.privilege_name),function(coupon,index){return _c('div',{key:index,staticClass:"badge badge-secondary"},[_vm._v(" "+_vm._s(coupon)+" ")])}),0)],1)]}},{key:"cell(allow_customer_redeem)",fn:function({ item }){return [_vm._v(" "+_vm._s(item.allow_customer_redeem ? "By Customer" : "By Booth")+" ")]}},{key:"cell(availability)",fn:function({ item }){return [_vm._v(" "+_vm._s(item.availability == 1 ? "Publish" : "Personalized")+" ")]}},{key:"cell(max_quantity)",fn:function({ item }){return [_vm._v(" "+_vm._s(item.max_quantity == 0 ? "Unlimited" : item.max_quantity)+" ")]}},{key:"cell(validFrom)",fn:function(data){return [(
              data.item.validFrom == data.item.validTo &&
              data.item.valid_type_id == 2
            )?[_vm._v(" - ")]:[_c('div',[_vm._v(_vm._s(_vm._f("moment")(data.item.validFrom,_vm.$formatDateNew)))]),_c('div',{staticClass:"time-color"},[_vm._v(" "+_vm._s(_vm._f("moment")(data.item.validFrom,"HH:mm:ss"))+" ")])]]}},{key:"cell(validTo)",fn:function(data){return [(
              data.item.validFrom == data.item.validTo &&
              data.item.valid_type_id == 2
            )?[_vm._v(" - ")]:[_c('div',[_vm._v(_vm._s(_vm._f("moment")(data.item.validTo,_vm.$formatDateNew)))]),_c('div',{staticClass:"time-color"},[_vm._v(" "+_vm._s(_vm._f("moment")(data.item.validTo,"HH:mm:ss"))+" ")])]]}},{key:"cell(status)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-center"},[(data.item.status === 1)?_c('span',{staticClass:"text-success"},[_vm._v("Active")]):_vm._e(),(data.item.status === 0)?_c('span',{staticClass:"text-error"},[_vm._v("Inactive")]):_vm._e()])]}},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-black my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',{staticClass:"ml-2"},[_vm._v("Loading...")])],1)]},proxy:true}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }